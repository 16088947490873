
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import trhLogo from '../static/trhLogo.png';
import SplitsEntityOverview from '../splitsEntityOverview/SplitsEntityOverview';
import MarketingEntityOverview from '../marketingEntityOverview/MarketingEntityOverview';
import CashFlow from '../cashFlow/CashFlow';
import LeadsByMarketingLine from '../leadsByMarketingLine/LeadsByMarketingLine';
import LostMoney from '../lostMoney/LostMoney';
import LostMoneyBreakdown from '../lostMoneyBreakdown/LostMoneyBreakdown';
import ProductivityReport from '../productivityReport/ProductivityReport';
import OpenerReport from '../openerReport/OpenerReport';
import DispoReport from '../dispoReport/DispoReport';
import DialerReport from '../dialerReport/DialerReport';
import LogicsActivity from '../logicsActivity/LogicsActivity';
import LienReport from '../lienReport/LienReport';

import './ReportQuery.css';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeApp } from 'firebase/app';
import SplitsEntityOverviewV2 from '../splitsEntityOverviewV2/SplitsEntityOverviewV2';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';

// Firebase project configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_ID,
  appId: process.env.REACT_APP_APP_ID,
};

const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
export function ReportQuery({ managerList, adminList, loggedInUser }) {
  const datePickerRef = useRef(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  
// Need this also to display the date range in the date picker
  // It's a list b/c you can do several date ranges (not applicable to our case)
  const [calendarDates, setCalendarDates] = useState(() => {
    const today = new Date();
    const monday = new Date(today);
    monday.setDate(today.getDate() - today.getDay() + 1); // Set to Monday of current week
    const friday = new Date(today);
    friday.setDate(today.getDate() - today.getDay() + 5); // Set to Friday of current week
    
    return [{
        startDate: monday,
        endDate: friday,
        key: 'selection'
    }];
  });

  // If user clicks the Calendar toggle and wants to close it by clicking outside of it,
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        setShowDatePicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  // Create state for include estimates toggle
  const [includeEstimates, setIncludeEstimates] = useState(true);
  const [showEstimateToggle, setShowEstimateToggle] = useState(false);

  const [reportName, setReportName] = useState('');
  const [reportComp, setReportComp] = useState(null);
  const [, setLoggedInUser] = useState({});
  let [loggedIn, setLoggedIn] = useState(true);

  const updateReport = (e) => {
    setReportName(e.target.value);
    console.log("e.target.value: " + e.target.value);

    // Show the toggle if the report is LeadsByMarketingLine
    if (e.target.value === 'leadsByMarketingLine') {
      setShowEstimateToggle(true);
    } else {
      setShowEstimateToggle(false);
    }
  };

  const updateToggle = () => {
    setIncludeEstimates(!includeEstimates);

    // For the Include Estimate toggle, we need to have this additional setReportComp so that we could get the component to re-render when
    // the toggle is clicked. Otherwise, it will only re-render the Fetch Report button is clicked.
    let rc = <LeadsByMarketingLine 
              startDate={calendarDates[0].startDate.toLocaleDateString()} 
              endDate={calendarDates[0].endDate.toLocaleDateString()} 
              reportName={'leadsByMarketingLine'} 
              includeEstimates={!includeEstimates} />;

    setReportComp(rc)
  }

  const displayReport = (e) => {
    let rc = null;
    if (calendarDates[0].startDate.toLocaleDateString() !== '' && calendarDates[0].endDate.toLocaleDateString() !== '') {
      if (reportName === 'splitsEntityOverview') {
        rc = <SplitsEntityOverview startDate={calendarDates[0].startDate.toLocaleDateString()} endDate={calendarDates[0].endDate.toLocaleDateString()} reportName={'splitsEntityOverview'} />;
      } else if (reportName === 'cashFlow') {
        rc = <CashFlow startDate={calendarDates[0].startDate.toLocaleDateString()} endDate={calendarDates[0].endDate.toLocaleDateString()} reportName={'cashFlow'} />;
      } else if (reportName === 'leadsByMarketingLine') {
        // console.log("passing to LeadsByMarketingLine includeEstimates: ", includeEstimates);
        rc = <LeadsByMarketingLine 
              startDate={calendarDates[0].startDate.toLocaleDateString()} 
              endDate={calendarDates[0].endDate.toLocaleDateString()} 
              reportName={'leadsByMarketingLine'} 
              includeEstimates={includeEstimates} />;
      } else if (reportName === 'lostMoney') {
        rc = <LostMoney startDate={calendarDates[0].startDate.toLocaleDateString()} endDate={calendarDates[0].endDate.toLocaleDateString()} reportName={'lostMoney'} />;
      } else if (reportName === 'lostMoneyBreakdown') {
        rc = <LostMoneyBreakdown startDate={calendarDates[0].startDate.toLocaleDateString()} endDate={calendarDates[0].endDate.toLocaleDateString()} reportName={'lostMoneyBreakdown'} />;
      } else if (reportName === 'productivityReport') {
        rc = <ProductivityReport startDate={calendarDates[0].startDate.toLocaleDateString()} endDate={calendarDates[0].endDate.toLocaleDateString()} reportName={'productivityReport'} />;
      } else if (reportName === 'openerReport') {
        rc = <OpenerReport startDate={calendarDates[0].startDate.toLocaleDateString()} endDate={calendarDates[0].endDate.toLocaleDateString()} reportName={'openerReport'} />;
      } else if (reportName === 'marketingEntityOverview') {
        rc = <MarketingEntityOverview startDate={calendarDates[0].startDate.toLocaleDateString()} endDate={calendarDates[0].endDate.toLocaleDateString()} reportName={'marketingEntityOverview'} />;
      } else if (reportName === 'dispositionReport') {
        rc = <DispoReport startDate={calendarDates[0].startDate.toLocaleDateString()} endDate={calendarDates[0].endDate.toLocaleDateString()} reportName={'dispositionReport'} />;
      } else if (reportName === 'dialerReport') {
        rc = <DialerReport startDate={calendarDates[0].startDate.toLocaleDateString()} endDate={calendarDates[0].endDate.toLocaleDateString()} reportName={'dialerReport'} />;
      } else if (reportName === 'logicsActivity') {
        rc = <LogicsActivity startDate={calendarDates[0].startDate.toLocaleDateString()} endDate={calendarDates[0].endDate.toLocaleDateString()} reportName={'logicsActivity'} />;
      } else if (reportName === 'lienReport') {
        rc = <LienReport startDate={calendarDates[0].startDate.toLocaleDateString()} endDate={calendarDates[0].endDate.toLocaleDateString()} reportName={'lienReport'} />;
      } else if (reportName === 'splitsEntityOverviewV2') {
        rc = <SplitsEntityOverviewV2 startDate={calendarDates[0].startDate.toLocaleDateString()} endDate={calendarDates[0].endDate.toLocaleDateString()} reportName={'splitsEntityOverviewV2'} />;
      }
    }
    setReportComp(rc);
  };

  const managerReports = {
    splitsEntityOverview: 'Splits Entity Overview',
    productivityReport: 'Productivity Report',
    openerReport: 'Opener Report',
    marketingEntityOverview: 'Marketing Entity Overview',
    dispositionReport: 'Disposition Report',
    dialerReport: "Dialer Report",
    logicsActivity: 'Logics Activity'
  };

  const adminReports = {
    cashFlow: 'Cash Flow',
    leadsByMarketingLine: 'Leads By Marketing Line',
    lostMoney: 'Lost Money',
    lostMoneyBreakdown: 'Lost Money Breakdown',
    lienReport: 'Lien Report',
  };

  // If the user is a manager, they will get the manager report, if they are admin we combine both lists here
  const reportOptionsMap = managerList && loggedInUser && loggedInUser.email && managerList.includes(loggedInUser.email.toLowerCase())
    ? managerReports
    : adminList && loggedInUser && loggedInUser.email && adminList.includes(loggedInUser.email.toLowerCase())
    ? { ...managerReports, ...adminReports }
    : {};

  // Sort the reports
  const sortedOptions = Object.entries(reportOptionsMap).sort()
  const optionElements = sortedOptions.map(([key, value]) => (
    <option key={key} value={key}>
      {value}
    </option>
  ));

  // Check logged in state
  onAuthStateChanged(auth, (user) => {
    // console.log("user: ", user);
    // Set the user to the state
    setLoggedInUser(user);
    if (user && user.emailVerified) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      // console.log("user.email: ", user.email);
    } else {
      console.log("NOT LOGGED IN/VERIFIED");
      setLoggedIn(false);
    }
  });

  const navigate = useNavigate();
  if(!loggedIn)  {
    return navigate("/");
  }

  const gotoDashboard = () => {
    return navigate("/dashboard");
  }

    // Add this function to format the date range display
  const formatDateRange = () => {
    return `${calendarDates[0].startDate.toLocaleDateString()} - ${calendarDates[0].endDate.toLocaleDateString()}`;
  };
    
  return (
    <div className=''>
      <div className='topBar'>
        <div className='f justify-center align-center'>
          <div className='f center-text'>
            <img src={trhLogo} alt="Logo" height={50} className='m-right-2' />
          </div>
          <div className='f center-text m-left-2 m-right-2'>
              <button className='blueButton' onClick={(e) => gotoDashboard(e)}>Dashboard</button>
            </div>
          <div className='f gap-5'>
            {/* Logout button */}
            {/* <button className='m-right-2 blueButton redBg' onClick={() => userLogOut()}>Logout</button> */}
            <label className='align-content-center'>Report Name: </label>
            <select
              name="reportName"
              className='large-font rounded-5 dropdown-input'
              onChange={(e) => updateReport(e)}
            >
              <option value={""}></option>
              {optionElements}
            </select>
          </div>
          <div className='date-picker-container m-left-2' ref={datePickerRef}>
          <label className='large-font'>Date Range: </label>
            <button 
              className='large-font rounded-5 dropdown-input' 
              onClick={() => setShowDatePicker(!showDatePicker)}>
                {formatDateRange()}
            </button>
            
            {showDatePicker && (
              <div>
                <DateRangePicker
                    className='date-picker-wrapper'
                    onChange={item => {
                      setCalendarDates([item.selection]);
                    }}
                    showSelectionPreview={true}
                    // moveRangeOnFirstSelection={false}
                    // retainEndDateOnFirstSelection={true}
                    months={1}
                    ranges={calendarDates}
                    direction="vertical"
                    editableDateInputs={true}
                    dateDisplayFormat="MM/dd/yyyy"
                />
                </div>
            )}
          </div>
          <div className='f center-text m-left-2'>
            <button className='blueButton' onClick={() => displayReport()}>Fetch Report</button>
          </div>
          <div className='f center-text m-left-2' style={{ minWidth: '205px' }}>
          {showEstimateToggle && (
              <>
              <Toggle
                  id='estimate-toggle'
                  checked={includeEstimates}
                  onChange={() => updateToggle()}
              />
              <label className='pad-left-5' htmlFor='estimate-toggle'>Include Estimates</label>
              </>
          )}
          </div>
        </div>
      </div>
      <div id="reportBox">{reportComp}</div>
    </div>
  );
};
export default ReportQuery;
