import React from 'react';
import "./Modal.css"

export default function Modal({toggleModal, updateNumModal, addNumberToBlock, handleKeyDownModal, addButtonDisabled}) {

    return (
        <>
        <div className='modal'>
        <div className='overlay'></div>
        <div className='modal-content'>
            <h4>Block Number</h4>
            <p>Please enter number to block. Phone number should contain only numbers and be exactly 10 digits.</p>
            {/* <input id='blockNumberInput' type='text'></input> */}
            <input 
            onChange={(e) => updateNumModal(e)} 
            onKeyDown={(e) => handleKeyDownModal(e)}
            id='blockNumberInput'
            autoFocus
            // onKeyDown={(e) => handleKeyDown(e)}
            type={"tel"} />
            <button 
                className='close-modal'
                onClick={toggleModal}>
                X
            </button>
            <button 
                className='blueButton float-right'
                onClick={addNumberToBlock}
                disabled={addButtonDisabled}>
                Add
            </button>
        </div>
        </div>
        </>   
    )
}