// Import FirebaseAuth and firebase.
import React, { useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import trhLogo from '../static/trhLogo.png'

import { Circles } from 'react-loading-icons'

// const provider = new GoogleAuthProvider();

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_ID,
  appId: process.env.REACT_APP_APP_ID,
};

// Allowed Email Doamins 
const allowed_domains = [
  "taxreliefhelpers.com",
  "allstatetaxgroup.com",
  "taxsupportteam.com",
  "chasingnectar.com"
];

// Check password format
function isPasswordOk(pwd) {
  const pwdReqs = "Password must be at least 8 character, have 1 capital, 1 number, and 1 special character";
  if(pwd.length < 8) {
    alert(pwdReqs);
    return false;
  }
  // check capital, num, and special character
  if( !pwd.match(/[A-Z]/) || !/\d/.test(pwd)) {
    alert(pwdReqs)
    return false;
  }

  return true;
} 

const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);


export default function Login() {

  const [userEmail, setUserEmail] = useState("");
  const [userPass, setUserPass] = useState("");

  let [buttonsDisabled, setButtonsDisabled] = useState(false);
  let [loading, setLoading] = useState("none");

  // Check logged in state
  onAuthStateChanged(auth, (user) => {
    if (user && user.emailVerified) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      const uid = user.uid;
      console.log("uid: ", uid);
      navigate("/dashboard")
    }  else {
      // No User/Not Verified
    }
  })

  const navigate = useNavigate();
  
  const emailRegex = /[a-zA-Z0-9]+@[a-zA-Z]+\.[a-z]{2,3}/;
  
  // Send to signup page
  const handleSignup = () => {
    navigate("/signup")
  }

  // Handle Signin
  const handleSignIn = () => {
    setButtonsDisabled(true);
    setLoading("block");
    // console.log("LOGGIN IN");
    // setUserEmail(userEmail.toLowerCase().trim());
    let domain = userEmail.split("@")[1];

    // Check user Email format and domain
    if(!emailRegex.test(userEmail) || !allowed_domains.includes(domain)) {
      setButtonsDisabled(false);
      setLoading("none");
      alert("Email Invalid");
      return
    }
    // Check Password
    if(!isPasswordOk(userPass)) {
      setButtonsDisabled(false);
      setLoading("none");
      alert("Invalid Pass")
      return;
    }

    // email/password is valid so sign in the user
    signInWithEmailAndPassword(auth, userEmail, userPass)
    .then((userCredential) => {
      if(!auth.currentUser.emailVerified) {
        // Let them know to verify
        sendEmailVerification(auth.currentUser);
        alert("Please check your inbox for an email verification");
      }
      // console.log("userCredential: ", userCredential);
      // User logged in redirect to the dash
      // Set logged in to true
      // setLoggedIn(true);
    })
    .catch((error) => {
      const errorMessage = error.message;
      console.log(error);
      alert("Unable to sign in: " + errorMessage);
    })
    setButtonsDisabled(false);
    setLoading("none");
  }

  // Send password reset link to email entered
  const sendPasswordReset = () => {
    setButtonsDisabled(true);
    setLoading("block");
    let domain = userEmail.split("@")[1];

    // Check user Email format and domain
    if(!emailRegex.test(userEmail) || !allowed_domains.includes(domain)) {
      setButtonsDisabled(false);
      setLoading("none");
      alert("Email Invalid");
      return
    }

    // Send link to email
    sendPasswordResetEmail(auth,userEmail)
      .then(() => {
        alert("Please check your inbox for a reset link");
      })
      .catch((error) => {
        alert("Unable to send reset link: " + error.message);
      })
    setButtonsDisabled(false);
    setLoading("none");
  }

  return (
    <div>
      <div className='text-center' style={{display: loading}}>
        <Circles stroke="#98ff98" />
      </div>
      <div id='loginBox' className='max-w-25 center m-top-5 whiteBg'>
        <div className='text-center'>
          <img src={trhLogo} alt="Logo" height={50}/> 
        </div>
        <div className='m-top-3 text-center'>
          <label for='email'>Email:</label>
          <input onChange={ (e) => setUserEmail(e.target.value) } name='email' className='m-left-3' type={"email"} />
        </div>
        <div className='m-top-3 text-center'>
          <label for='pwd'>Password:</label>
          <input onChange={ (e) => setUserPass(e.target.value) } name='pwd' className='m-left-2' type={"password"} />
        </div>
        <div className='text-center'>
          <button className='blueButton m-top-3' disabled={buttonsDisabled} onClick={() => handleSignIn()}>Login</button><br/>
          <button className='blueButton m-top-3' disabled={buttonsDisabled} onClick={() => handleSignup()}>Sign Up</button>
          {/* Forgot Password Link */}
          <p className='m-top-3 underline blue-text' onClick={() => sendPasswordReset()}>Reset Password</p>
        </div>
      </div>
    </div>
  )
} 