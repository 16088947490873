import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';

import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import './DispoReport.css'
import "ag-grid-enterprise";
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';

import { dateFormatter } from '../valueformatter';

export default function DispoReport(props) {
	const [gridOptions, setGridOptions] = useState({
		sideBar: true,
		rowGroupPanelShow: 'always',
		// groupDefaultExpanded: 1,
		columnHoverHighlight: true,
		// groupIncludeFooter: true,
		groupIncludeTotalFooter: true,
		suppressAggFuncInHeader: true,
		autoGroupColumnDef: {
			cellRendererParams: {
				footerValueGetter: params => {
					const isRootLevel = params.node.level === -1;
					console.log("isRootLevel: ", isRootLevel);
					if (isRootLevel) {
						return 'Grand Total';
					}
					return `Sub Total (${params.value})`;
				},
			},
		},
		getRowStyle: params => {
			// Grand Total row formatting
			if (params.node.group === true && params.node.level === -1) {
				return { background: '#3F91B8', color: 'white', fontWeight: 'bold' };
				// #b5c4ca
				// #99bac9
			}
			// Subtotal row formatting
			else if (params.node.group === true) {
				return { background: '#b5c4ca', fontWeight: 'bold' };
			}
		},
		autoSizeStrategy: {
			type: 'fitCellContents'
		},
	});

	const gridRef = useRef(); // Optional - for accessing Grid's API

	// DefaultColDef sets props common to all Columns
	const defaultColDef = useMemo(() => ({
		filter: true,
	}));

	// Column Definitions: Defines the columns to be displayed.
	const [colDefs, setColDefs] = useState([
		{ field: "company", headerName: "Company", sort: "asc", enableRowGroup: true, rowGroup: true, hide: true },
		{ field: "agent_name", headerName: "Agent Name", sort: "asc", enableRowGroup: true },
		{
			field: "contact_start", headerName: "Date", enableRowGroup: true,
			valueFormatter: params => dateFormatter(params.value),
		},
		{ field: "skill_name", headerName: "Lead Source", enableRowGroup: true, },
		{ field: "from_addr", headerName: "Telephone", enableRowGroup: true, },
		{ field: "disposition_notes", headerName: "Comments", enableRowGroup: true, },
		{ field: "name", headerName: "Result", enableRowGroup: true, },
	]);

	// Row Data: The data to be displayed.
	const [rowData, setRowData] = useState([]);

	// Preserving Filter Model
	const [filterModel, setFilterModel] = useState(null);  // Save current filters

	// Loading state to track when data is being fetched
	const [loading, setLoading] = useState(true);

	// Fetch data based on date range
	useEffect(() => {
		const fetchData = async () => {
			setLoading(true); // Set loading to true before fetching
			if (gridRef.current) {
				// Save current filter state
				const currentFilterModel = gridRef.current.api.getFilterModel();
				setFilterModel(currentFilterModel);
			}

			const requestOptions = {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' },
			};
			const reqUrl = `${process.env.REACT_APP_API_URL}/api/reports?report=${props.reportName}&startDate=${props.startDate}&endDate=${props.endDate}`;

			try {
				const result = await fetch(reqUrl, requestOptions);
				const gridData = await result.json();
				setRowData(gridData.data);
			} catch (error) {
				console.error("ERROR: " + error);
			} finally {
				setLoading(false); // Set loading to false after fetching
			}
		};

		fetchData();
	}, [props.reportName, props.startDate, props.endDate]);

	// After new data is set, reapply the filter model
	useEffect(() => {
		if (gridRef.current && filterModel) {
			gridRef.current.api.setFilterModel(filterModel);
		}
	}, [rowData]);

	// If rows have not yet been returned
	// if (loading) {
	//   return (
	//     <div>
	//       <h1 className='color2-font'>DATA LOADING</h1>
	//     </div>
	//   )
	// }

	return (
		// wrapping container with theme & size
		<div
			className="ag-theme-quartz h-75 greyBg" // applying the grid theme
		// style={{ height: 100% }} // the grid will fill the size of the parent container
		>
			<div id="summaryBox">
				<p id="summaryText">
					<strong>Disposition Report</strong><br />
					This view will show dispositions left by Sales People on calls they took in the Contact Center.
					<br />
				</p>
			</div>
			<div style={{ position: 'relative', height: '100%' }}> {/* New wrapper for AG Grid */}
				<AgGridReact
					enableCharts={true}
					enableRangeSelection={true}
					rowData={rowData}
					columnDefs={colDefs}
					// getGroupRowAgg={getGroupRowAgg}
					gridOptions={gridOptions}
					defaultColDef={defaultColDef}
					modules={[ServerSideRowModelModule, RowGroupingModule]}
					defaultSideBar={false}
					sideBar={{
						toolPanels: [
							{
								id: 'columns',
								labelDefault: 'Columns',
								labelKey: 'columns',
								iconKey: 'columns',
								toolPanel: 'agColumnsToolPanel',
							},
							{
								id: 'filters',
								labelDefault: 'Filters',
								labelKey: 'filters',
								iconKey: 'filter',
								toolPanel: 'agFiltersToolPanel',
							}
						]
					}}
				// rowGroupPanelShow={rowGroupPanelShow}
				/>
				{loading && (
					<div className="loading-overlay">
						<div className="spinner"></div>
					</div>
				)}
			</div>
		</div>
	)
}